<template>
  <a-modal v-model="visible"
           :title="null"
           :footer="null"
           :centered="true"
           :closable="false"
           :after-close="reset"
           width="248px"
           @cancel="close()">
    <img :src="qrUrl" alt="" class="img">

    <div class="close-btn" @click="close()">
      <a-icon type="close"/>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'QRModal',
  data() {
    return {
      visible: false,

      qrUrl: '',
    };
  },
  methods: {
    show({ qrUrl }) {
      this.qrUrl = qrUrl;

      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    reset() {
      this.qrUrl = '';
      this.imgBase64 = null;
    },
  },
};
</script>

<style scoped lang="scss">
.img {
  width: 240px;
  height: 240px;
  margin: -20px;
}

.close-btn {
  $size: 30px;

  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: $size;
  height: $size;
  line-height: $size;
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: #333333;
  background-color: #FFFFFF;
  cursor: pointer;
}
</style>
