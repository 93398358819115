<template>
  <div>
    <div class="flex flex-start flex-wrap ph-20 mb-5">
      <div class="flex flex-start mr-20 mb-5">
        <span class="mr-10 flex-none">组织机构:</span>
        <app-select-org v-model="form.org_id_str" :allow-clear="true"/>
      </div>
      <div class="flex flex-start mr-20 mb-5">
        <span class="mr-10 flex-none">岗位:</span>
        <app-select-job v-model="form.post_id_str" :allow-clear="true"/>
      </div>
      <div class="flex flex-start mr-20 mb-5">
        <span class="mr-10 flex-none">学员搜索:</span>
        <a-input v-model="form.truename_or_username_or_idcard"
                 :allow-clear="true"
                 placeholder="请输入学员姓名或身份证号"
                 style="width: 260px;"/>
      </div>
    </div>

    <div class="flex flex-end ph-20 mb-10">
      <div class="flex flex-start flex-grow">{{ $route.query.name }}: 共找到 {{ pagination.total || 0 }} 份样本</div>
      <a-button type="primary" @click="onSearch">查询</a-button>
      <a-button type="default" class="mr-30" @click="onReset(true)">重置</a-button>
      <a-button type="primary"
                :loading="downloading"
                class="mr-20"
                @click="onExportExcel()">
        导出全部样本结果
      </a-button>

      <a-button type="default" @click="back()">返回</a-button>
    </div>

    <div class="ph-20 full-width">
      <a-table ref="tableRef"
               :columns="columns"
               :data-source="data"
               :pagination="false"
               :loading="fetching"
               :scroll="{x: '100%'}"
               bordered
               row-key="rowId"
               class="table small-cell-table">
        <template slot="action" slot-scope="text, record">
          <a-button type="link" @click="viewRespondentDetail(record)">个人详情</a-button>
        </template>

        <template slot="footer">
          <Pagination v-if="pagination && pagination.total"
                      :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"/>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import table from '@/mixins/table';

import {
  getResultList,
  getResultListDownload,
} from './api';

const columns = [
  {
    title: '作答ID',
    dataIndex: 'id',
    key: 'id',
    width: '120px',
    align: 'center',
    fixed: 'left',
  },
  {
    title: '大区',
    dataIndex: 'regional_org_name',
    key: 'regional_org_name',
    width: '140px',
    align: 'center',
  },
  {
    title: '小区',
    dataIndex: 'small_regional_org_name',
    key: 'small_regional_org_name',
    width: '160px',
    align: 'center',
  },
  {
    title: '经销商',
    dataIndex: 'orgname',
    key: 'orgname',
    width: '240px',
    align: 'center',
    autoExpand: true,
  },
  {
    title: '机构代码',
    dataIndex: 'code',
    key: 'code',
    width: '120px',
    align: 'center',
  },
  {
    title: '作答人',
    dataIndex: 'truename',
    key: 'truename',
    width: '100px',
    align: 'center',
  },
  {
    title: '岗位',
    dataIndex: 'postname',
    key: 'postname',
    width: '180px',
    align: 'center',
  },
  {
    title: '电话',
    dataIndex: 'mobile',
    key: 'mobile',
    width: '120px',
    align: 'center',
  },
  {
    title: '身份证号',
    dataIndex: 'idcard',
    key: 'idcard',
    width: '180px',
    align: 'center',
  },
  {
    title: '作答时间',
    dataIndex: 'completed_at',
    key: 'completed_at',
    width: '170px',
    align: 'center',
    autoExpand: true,
    customRender: (text) => text ? moment(text * 1000).format('YYYY-MM-DD') : '--',
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    width: '100px',
    align: 'center',
    scopedSlots: { customRender: 'action' },
    fixed: 'right',
  },
];

export default {
  name: 'Result',
  mixins: [table],
  data() {
    return {
      defaultForm: {
        survey_sn: this.$route.query.sn || '',
        org_id_str: '',
        post_id_str: '',
        truename_or_username_or_idcard: '',
        is_download: 0,
      },

      originColumns: columns,
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    back() {
      this.$router.back();
    },

    getData(page, pageSize) {
      this.executeGetData(async () => {
        const params = {
          page,
          per_page: pageSize,
          ...this.form,
        };

        const data = await getResultList(params);
        if (!data || data.error_code) {
          this.$message.error(data?.message || '获取列表失败');
          return Promise.reject(data);
        }
        this.data = (data?.data || []).map((i, index) => ({
          ...i,
          rowId: (page - 1) * pageSize + index + 1,
        }));

        return Promise.resolve(data);
      });
    },

    onExportExcel() {
      this.executeExportExcel(async () => {
        const params = {
          page: this.pagination.current,
          per_page: this.pagination.pageSize,
          ...this.form,
          is_download: 1,
        };

        const data = await getResultListDownload(params);
        if (!data || data.error_code) {
          return Promise.reject(data);
        }

        return Promise.resolve(data);
      });
    },

    viewRespondentDetail(record) {
      this.$router.push({
        path: './respondent',
        query: {
          sn: record.sn,
          id: record.id,
          name: this.$route.query?.name || '',
        },
      });
    },
  },
};
</script>
