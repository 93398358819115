<template>
  <div>
    <div class="flex flex-end mb-10 ph-20">
      <a-button type="default" @click="back()">返回</a-button>
    </div>

    <a-descriptions bordered size="small"
                    :column="3"
                    class="ph-20 mb-10">
      <a-descriptions-item label="问卷名称" :span="3">
        {{ survey.project_name }}
      </a-descriptions-item>
      <a-descriptions-item label="作答人">
        {{ survey.truename || '' }}
      </a-descriptions-item>
      <a-descriptions-item label="岗位">
        {{ survey.postname || '' }}
      </a-descriptions-item>
      <a-descriptions-item label="机构">
        {{ survey.orgname || '' }}
      </a-descriptions-item>
    </a-descriptions>

    <div class="ph-20 full-width">
      <a-table ref="tableRef"
               :columns="columns"
               :data-source="data"
               :pagination="false"
               :loading="fetching"
               bordered
               row-key="rowId"
               class="table small-cell-table">
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total"
                      :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"/>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import table from '@/mixins/table';

import { getRespondentList } from './api';

const columns = [
  {
    title: '序号',
    dataIndex: 'seq',
    key: 'seq',
    width: '120px',
    align: 'center',
  },
  {
    title: '类型',
    dataIndex: 'question_type',
    key: 'question_type',
    width: '120px',
    align: 'center',
  },
  {
    title: '题干',
    dataIndex: 'title',
    key: 'title',
    width: '180px',
    align: 'center',
  },
  {
    title: '用户作答',
    dataIndex: 'answer',
    key: 'answer',
    width: '180px',
    align: 'center',
  },
];

export default {
  name: 'Respondent',
  mixins: [table],
  data() {
    return {
      defaultForm: {
        id: this.$route.query.id || '',
      },

      originColumns: columns,

      survey: {},
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    back() {
      this.$router.back();
    },

    getData(page, pageSize) {
      this.executeGetData(async () => {
        const params = {
          page,
          per_page: pageSize,
          ...this.form,
        };

        const data = await getRespondentList(params);
        if (!data || data.error_code) {
          this.$message.error(data?.message || '获取列表失败');
          return Promise.reject(data);
        }
        this.survey = data?.data || {};
        this.survey.project_name = this.$route.query?.name || '';
        const content = data?.data?.content || {};
        this.data = Object.keys(content).map((key, index) => {
          const item = { ...content[key] };
          item.seq = key;
          item.rowId = (page - 1) * pageSize + index + 1;

          return item;
        });

        return Promise.resolve(data);
      });
    },
  },
};
</script>
