import { stringify } from 'qs';
import { baseUrl } from '@/Config';

import request from '@/utils/Request';
import {
  downloadFileExtra,
} from '@/utils/JsDownload';

import store from '@/store';

// 调研-问卷列表
export function getSurveyList(params) {
  return request({
    url: '/research/admin/list',
    method: 'get',
    params,
  });
}

// 调研-作答列表
export function getResultList(params) {
  return request({
    url: '/research/admin/answer',
    method: 'get',
    params,
  });
}

// 调研-作答列表 - 下载
export function getResultListDownload(params) {
  let url = '/research/admin/answer';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params, { encode: false })}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

// 调研-作答列表
export function getRespondentList(params) {
  return request({
    url: `/research/admin/answer/${params.id}`,
    method: 'get',
    params,
  });
}
